import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic_one from '../images/one.png'
import pic_air from '../images/AIR-JARVIS.png'
import GatsbyConfig from '../../gatsby-config'

import axios from "axios"
import * as qs from "query-string"

class Main extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
  }
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null }
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    const formData = {'subj':GatsbyConfig.plugins[1].options.consts.ContactForm.subject,'emailto':GatsbyConfig.plugins[1].options.consts.ContactForm.emailto.toString()}
    Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))
    
    const axiosOptions = {
      url: "https://features.konductor.net/mailer.php",
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }
    
    axios(axiosOptions)
      .then(response => {
        console.log(response.data.status);
        
        if(response.data.status === "sent"){
          this.setState({
            feedbackMsg: "Form submitted successfully!",
          })
          
          this.domRef.current.reset();
          
          setTimeout(() => {
            this.props.onCloseArticle()
          }, 1000);
        }else{
          this.setState({
            feedbackMsg: response.data.status,
          })
        }
      })
      .catch(err =>
        this.setState({
          feedbackMsg: "Form could not be submitted.",
        }),
      )
  }
  
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
        role="button"
        tabIndex={0}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin
            aliquam facilisis ante interdum congue. Integer mollis, nisl amet
            convallis, porttitor magna ullamcorper, amet egestas mauris. Ut
            magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas.
            By the way, check out my <a href="#work">awesome work</a>.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
            dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Etiam tristique
            libero eu nibh porttitor fermentum. Nullam venenatis erat id
            vehicula viverra. Nunc ultrices eros ut ultricies condimentum.
            Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae
            dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in
            lectus. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. In non lorem sit amet elit
            placerat maximus. Pellentesque aliquam maximus risus, vel sed
            vehicula.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
            <div className="row">
              <div className="col image-box"><img src={pic_one} width="148" height="89" alt="" /></div>
              <div className="col">
                <h3>2020 IATA Cargo Hackathon winner</h3>
                <p>
                  The ECARGO team recieved 1st place in the <br/>
                  2020 Hackathon challenge sponsored by <br/>
                  IATA.ORG. Over 170 participants, 22 teams in <br/> 
                  over 50 countries participated. 
                </p>
                <div>
                  <h3>View the project</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3>System Details</h3>
                <div>
                  ecargo developed on an AI solution for Air Canada to proactively manage irregular operations in the logistics supply chain.<br/>
                  The Java-based automated rebooking visual integrated system (Air J.A.R.V.I.S) is an AI based solution to help the airline and forwarder understand precisely where the shipment is and when it will arrive at destination. From an administrative POV Air J.A.R.V.I.S. centralizes flight information, shipment adjustments and communication to all participants. 
                </div>
                <div>
                  The Air J.A.R.V.I.S. data models contain:
                  <ul>
                    <li>Weather information </li>
                    <li>Historical flight information </li>
                    <li>Booking behavior </li>
                    <li>Live flight GPS information </li>
                    <li>Air waybill information </li>
                  </ul>
                </div>
              </div>
              <div className="col image-box">
                <img src={pic_air} width="221" height="220" alt="" />
              </div>
            </div>
            <hr/>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>Deep understanding of ONE Record implementation</p>
          <p>Experience working with top logistics companies</p>
          <p>A diverse team spread across 11 time zones</p>
          <p>20+ years of systems integration experience</p>
          <p>&nbsp;</p>
          <hr/>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form action="https://formspree.io/brook@ecargo.io" method="POST">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message" 
                id="message"
                onChange={this.handleInputChange} 
                rows="4">{this.state.message}</textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="contact_mailer"
          className={`${this.props.article === 'contact_mailer' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>

          {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}
          <form ref={this.domRef} name="Contact Form" method="POST" data-netlify="true" onSubmit={event => this.handleSubmit(event)}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                ref="name"
                type="text"
                id="name"
                name="name"
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                ref="email"
                type="text"
                id="email"
                name="email"
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                ref="message"
                name="message" 
                id="message"
                rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
