import React from 'react'
import PropTypes from 'prop-types'
import pic01 from '../images/ecargo-300wide.png'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="">
        <img src={pic01} alt="" />
        </div>
        <div className="content">
            <div className="inner">
                <h1>One Record Solution</h1>
                <p>A complete solution to logistics complications. <br />Ecargo provides implementation for Airline Vendors, Companies and Ground Shippers.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li>
                    <button onClick={() => {props.onOpenArticle('work')}} >Work</button>
                </li>
                <li>
                    <button onClick={() => {props.onOpenArticle('about')}} >About</button>                    
                </li>
                <li>
                    <button onClick={() => {props.onOpenArticle('contact_mailer')}} >Contact</button>
                </li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
