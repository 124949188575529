/*
  NodeJS 14.20.1 requered!!!
  $: nvm install 14
  $: nvm use 14.20.1
  $: nvm use 14
*/
module.exports = {
  siteMetadata: {
    title: "Ecargo",
    author: "Hunter Chang",
    description: "A Gatsby.js V2 Starter based on Dimension by HTML5 UP"
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        consts: {
          ContactForm:{
            subject: "Ecargo.oi Contact Form",
            emailto: [
              "support@konductor.net",
              "brook@konductor.net",
              "brook@ecargo.io"
            ]
          }
        },
        bucketName: 'ecargo-site',  
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'gatsby-starter-default',
        short_name: 'starter',
        start_url: '/',
        background_color: '#663399',
        theme_color: '#663399',
        display: 'minimal-ui',
        icon: 'src/images/ecargo-logo.png', // This path is relative to the root of the site.
      }
    },
    'gatsby-plugin-sass',
    'gatsby-plugin-offline'
  ],
}
